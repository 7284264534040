
//金额格式 例如： 10,000,000
export function format(num) {
    let result = '';

    let flag = num < 0 ? '-' : '';

    let split_data = (Math.abs(num)).toString().split('.');

    let integer_data = split_data[0];
    let decimal_data = split_data[1] !== undefined ? ('.' + split_data[1]) : '';

    while (integer_data.length > 3) {
        result = ',' + integer_data.slice(-3) + result;
        integer_data = integer_data.slice(0, integer_data.length - 3);
    }

    if (integer_data) {
        result = integer_data + result;
    }

    return flag + result + decimal_data;
}
