<template>
    <div>
        <div>
            <Table :columns="list.columns" :data="list.data">
                <template slot-scope="{ key,row }" slot="certificate">
                    <p v-for="item in row.certificate" @click="showImg(item.url)" class="pointer">
                      <img class="w-50" :src="item.url+thumbnailUrl" alt="">
                    </p>
                </template>

                <template slot-scope="{ key,row }" slot="goods_pay">
                    <strong>{{row.goods_pay}}</strong>
                </template>

              <template slot-scope="{ row }" slot="status">
                <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
              </template>

              <template slot-scope="{ row }" slot="pay_status">
                <span class="pointer" :style="getPayStatusColor(row.pay_status)" >{{ getPayStatusText(row.pay_status) }}</span>
              </template>
            </Table>
            <div class="page">
                 <span style="margin:0 20px;float: left;">
                   <strong>总计: </strong>
                   <strong class="main-color">{{extra.total_price}}</strong>
                 </span>
                  <span style="margin:0 10px;float: left;" v-show="extra.total_real_pay > 0">
                   <strong>实收: </strong>
                   <strong class="main-color">{{extra.total_real_pay}}</strong>
                 </span>
                <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
                      :current="list.page" show-sizer @on-page-size-change="pageSizeChange"
                />
            </div>
        </div>


        <!--图片预览-->
        <Modal v-model="imageInfo.modal" :styles="{top: '20px'}" width="720" :title="imageInfo.modal_title" footer-hide>
            <div class="modalForm alignCenter">
                <img :src="imageInfo.url" alt="" class="pointer" style="max-height:760px;max-width: 640px">
            </div>
        </Modal>
    </div>

</template>

<script>
    import '@/css/common.css';
    import * as mf_util from '@/utils/moneyFormat';

    export default {
        name: "DataView",
        data () {
            return {
                transfer_id: '',
                data_columns: [],

                imageInfo: {
                    modal: false,
                    modal_title: '',
                    url: '',
                },
                thumbnailUrl:'?x-oss-process=image/resize,m_mfit,h_100,w_100',
                list: {
                    columns: [],
                    data: [],
                    total: 0,
                    size: 0,
                    page: 1,
                },
                extra:{
                    total_price: 0
                }
            }
        },
        created() {
            this.transfer_id = this.$route.query.transfer_id;
            this.getData();
        },
        methods: {
          getPayStatusText(index) {
            let rs = '';
            switch (index) {
              case 1:
                rs = '待收款';
                break;
              case 2:
                rs = '部分收款';
                break;
              case 3:
                rs = '收款完结';
                break;
              case 4:
                rs = '超额收款';
                break;
              default:
                rs = '';
                break;
            }

            return rs;
          },
          getPayStatusColor(index) {
            let rs = '';
            switch (index) {
              case 2:
                rs = 'color:#ff9900';
                break;
              case 3:
              case 4:
                rs = 'color:#2775ff';
                break;
              case 1:
                rs = 'color:#ed4014';
                break;
              default:
                rs = '';
                break;
            }

            return rs;
          },
          getStatusColor(index) {
            let rs = '';
            switch (index) {
              case 1:
                rs = 'color:#ed4014';
                break;
              case 2:
                rs = 'color:#19be6b';
                break;
              case 3:
              case 8:
              case 7:
                rs = 'color:#2db7f5';
                break;
              case 4:
              case 10:
                rs = 'color:#2775ff';
                break;
              case 5:
                rs = 'color:#c5c8ce';
                break;
              case 6:
              case 9:
                rs = 'color:#ff9900';
                break;
              default:
                rs = '';
                break;
            }

            return rs;
          },
          getStatusText(index) {
            let rs = '';
            switch (index) {
              case 1:
                rs = '待审核';
                break;
              case 2:
                rs = '待出库';
                break;
              case 3:
                rs = '已出库';
                break;
              case 4:
                rs = '已完成';
                break;
              case 7:
                rs = '已发货';
                break;
              case 8:
                rs = '出库中';
                break;
              case 5:
                rs = '已取消';
                break;
              case 6:
                rs = '已退货';
                break;
              case 9:
                rs = '有退货';
                break;
              case 10:
                rs = '已完成';//（该状态表示订单有退货）
                break;
              default:
                rs = '';
                break;
            }

            return rs;
          },
            //查看图片
            showImg(url) {
                if (url) {
                    this.imageInfo.url = url;
                    this.imageInfo.modal_title = '查看流水凭证';
                    this.imageInfo.modal = true;
                }
            },
            getData() {
                if (!this.transfer_id) {
                    return;
                }

                let param = {
                        page: this.list.page,
                        page_size: this.list.size,
                        transfer_id: this.transfer_id.toString(),
                };
                this.$axios.post('/api/common/getDataView', param).then((res) => {
                        if (res.data.err_no == 0) {
                            let data = res.data.results;
                            this.list.columns = data.info.title;
                            this.list.data = data.list;
                            this.list.total = data.total;
                            this.list.size = data.size;

                            data.info.total_price = mf_util.format(data.info.total_price);
                            data.info.total_real_pay = mf_util.format(data.info.real_pay);
                            this.extra = data.info;
                        }
                    });
            },
            changePage(page) {
                this.list.page = page;
                this.getData();
            },
            pageSizeChange(page_size) {
                this.list.size = page_size;
                this.getData();
            },
        }
    }
</script>

<style scoped>
    .page {
        margin-top: 16px;
    }
    .ivu-page {
        float: right;
    }
</style>
